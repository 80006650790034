.wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 5px;
}

.errorLabel {
    font-weight: lighter;
    color: red;
}

.textarea {
    border-radius: 5px;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;

    margin-bottom: 2px;
    border-width: 2px;
    border-style: revert;



}

.error > input {
    border-color: red;
}

.label {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 5px;
    color: #000000;
    text-align: left;
}
