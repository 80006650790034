.submitButton {
    background: #FD0054;
    border-radius: 4px;
    padding: 10px;
    border: none;
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 1px;
    text-align: center;
    color: #FFFFFF;
}

.submitButton:hover{
    opacity: 0.8;
}
