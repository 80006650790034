@tailwind base;
@tailwind components;
@tailwind utilities;
* {
    box-sizing: border-box;
}

@layer base {
    html {
        font-family: 'Plus Jakarta Sans', sans-serif;
        /*font-family: 'Plus Jakarta Sans','Lexend', sans-serif;*/
        @apply text-black bg-warm
    }

    body {
        font-family: 'Plus Jakarta Sans', sans-serif;
        /*font-family: 'Lexend', sans-serif;*/
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;

    }


    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 80px;

        /* identical to box height */


    }

    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;
    }

    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 35px;
    }

    h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
    }

    button {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }

    hr {
        border: 1px solid #F2EBE3;
        margin: 10px auto;
    }

    img {
        @apply object-cover
    }

}

@layer components {
    .dashboard-button {
        @apply text-white hover:bg-black px-8 py-2 rounded-md flex gap-1 items-center text-center cursor-pointer disabled:cursor-auto;
    }

    .guest-button {
        @apply text-white bg-gold-light border border-gold-light hover:border-gold px-8 py-2 rounded-lg flex gap-1 items-center text-center cursor-pointer disabled:cursor-auto;
    }

    .icon-text {
        @apply [&>*:nth-child(1)]:w-5 [&>*:nth-child(1)]:stroke-[1.5] flex gap-2 items-center
    }

    .dashboard-tr {
        @apply bg-white [&>td]:font-extralight [&>td]:py-3 first:[&>td]:first:rounded-tl last:[&>td]:first:rounded-tr first:[&>td]:last:rounded-bl last:[&>td]:last:rounded-br
    }

    .dashboard-tbody {
        @apply text-left [&>tr>th]:py-4
    }

    .dashboard-thead {
        @apply bg-[#F9F9F9FF] text-left [&>tr>th]:py-4 tracking-wider
    }

    .dashboard-table {
        @apply relative overflow-auto block md:table table-auto w-full [&>*>tr>td]:px-6 [&>*>tr>th]:font-normal  [&>*>tr>th]:px-6 rounded  border-separate border-spacing-y-0.5;
    }

    .card-container {
        @apply bg-white py-5 px-2 rounded shadow;
    }

    @keyframes loading-skeleton {
        100% {
            transform: translateX(100%);
        }
    }

    .triangle-nouch {
        @apply relative after:border after:bg-inherit after:border-inherit
        after:block after:h-3 after:w-3 after:absolute after:-right-1.5 after:rotate-45
    }

    .triangle-nouch:after {
        clip-path: polygon(0 0, 100% 100%, 100% 0%);
    }

    .loading-skeleton {
        --base-color: #ebebeb;
        --highlight-color: #f5f5f5;
        --animation-duration: 1.5s;
        --animation-direction: normal;
        --pseudo-element-display: block; /* Enable animation */
        /*opacity: 0.5;*/

        /*background-color: var(--base-color);*/

        width: 100%;
        border-radius: 0.25rem;
        display: inline-flex;
        line-height: 1;

        position: relative;
        overflow: hidden;
        /*z-index: 1; !* Necessary for overflow: hidden to work correctly in Safari *!*/
    }

    .loading-skeleton::after {
        opacity: 0.5;
        content: ' ';
        display: var(--pseudo-element-display);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background-repeat: no-repeat;
        background-image: linear-gradient(
                90deg,
                var(--base-color),
                var(--highlight-color),
                var(--base-color)
        );
        transform: translateX(-100%);

        animation-name: react-loading-skeleton;
        animation-direction: var(--animation-direction);
        animation-duration: var(--animation-duration);
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }

}


.font-cursive {
    font-family: 'Reenie Beanie', cursive !important;
}

.horizontal-slider {
    @apply flex items-center justify-between w-full h-2 rounded bg-red;
}

.horizontal-slider .example-track, .horizontal-slider .example-mark {
    @apply w-1 h-1 bg-white rounded-full;
}

.horizontal-slider .example-thumb {
    @apply flex items-center justify-center w-5 h-5 text-sm bg-white border rounded-full shadow-md outline-none cursor-pointer;
}
